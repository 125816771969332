import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

import InvestorsImage from "../components/investors/investorsImage"
import InvestorstitletextModule from "../components/investors/investorstitleModule"
import InvestorsfeatureBlueModule from"../components/investors/investorsfeatureBlueModule"
import FeatureModule from "../components/common/featureModule"
import InvestorstitleModule from "../components/common/titletextModule"
import CTAModule from "../components/common/ctaModule"

import { FeatureModel } from "../components/investors/model"
import { InvesttitleModel2 } from "../components/investors/model"

const InvestorsPage = () => (
  <Layout>
        <SEO title="Investors" />
        <Container className="pad_header">
            <h1 className="header_title">For Investors</h1>
        </Container>
        <InvestorsImage />
        <InvestorstitletextModule />
        <InvestorsfeatureBlueModule />
        <FeatureModule model={FeatureModel}/>
        <InvestorstitleModule model={InvesttitleModel2} css='pad_bot_35' />
        <CTAModule 
            title="Connect with a Jus-Pharma team member today!"
            css='background_e'
        />
  </Layout>
)

export default InvestorsPage

