import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { InvesttitleModel } from "./model"

const InvestorstitleModule = props => {
    return (
        <div className="section_pad_st1">
            <Container>
                <div className="max_width_850">
                    <h4 className="main_title main_b_color txt_align_center margin_bot_35">{InvesttitleModel.title}</h4>
                </div>
                <Row className="margin_0">
                    <Col
                        className="col-sm-6 pad_r_35"
                    >
                        <p className="main_text main_b_color">{InvesttitleModel.text1}</p>
                    </Col>
                    <Col
                        className="col-sm-6 pad_l_35"
                    >
                        <p className="main_text main_b_color">{InvesttitleModel.text2}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default InvestorstitleModule

