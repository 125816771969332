import React from "react"
import { Container, CardDeck, Card } from 'react-bootstrap'
import { CardModel } from "./model"

const InvestorsfeatureBlueModule = () => {
    return (
        <div className="section_pad_st1">
            <Container>
                <CardDeck className="margin_0">
                    {CardModel.map((item, i) => {
                        return (
                            <Card className="background_mask_blue text-center pad_35" key={i}>
                                <Card.Img className="margin_bot_35 mx-auto max_w_100" variant="top" src={item.icon} alt={item.subtitle}/>
                                <Card.Body className="pad_0">
                                    <Card.Title className="sub_title main_w_color">{item.subtitle}</Card.Title>
                                    <Card.Text className="main_text main_w_color">{item.subtext}</Card.Text>
                                </Card.Body>
                            </Card>
                          )
                    })}
                </CardDeck>
            </Container>
        </div>
    )
}

export default InvestorsfeatureBlueModule

